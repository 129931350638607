@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    scrollbar-color: #7655ae transparent;
    scrollbar-width: 10px;
}

body {
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    scrollbar-color: #5a55ae, transparent;
    scrollbar-width: thin;
}

#root {
    width: 100%;
}

.titlebar {
    width: 100%;
    height: 20%;
    display: table;
    position: fixed;
    justify-content: center;
    text-align: center;
    top: 0px;
    justify-content: center;
    overflow: hidden;
}

.background {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    position: fixed;
    background: linear-gradient(
        -45deg,
        #3f51b1 0%,
        #5a55ae 13%,
        #7b5fac 25%,
        #8f6aae 38%,
        #a86aa4 50%,
        #cc6b8e 62%,
        #f18271 75%,
        #f3a469 87%,
        #f7c978 100%
    );
    background-size: 400% 400%;
    animation: animate 30s ease-in-out infinite;
}

#Title {
    font-size: 72px;
    color: #fff;
    font-weight: 700;
    display: table-cell;
    vertical-align: middle;
}

.wrapper {
    width: 100%;
    height: 80%;
    min-height: 80vh;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    flex-wrap: wrap;
    overflow-y: auto;
}

.wrapper::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
}
.wrapper::-webkit-scrollbar-thumb {
    background: #7655ae;
    border-radius: 20px;
}

.wrapper .Card {
    position: relative;
    padding: 20px;
    width: 280px;
    height: 400px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, rgba(17, 17, 17, 0.2) 13%, rgba(34, 34, 34, 0.2) 25%, rgba(51, 51, 51, 0.2) 38%, rgba(229, 161, 13, 0.504) 50%, rgba(68, 68, 68, 0.2) 62%, rgba(85, 85, 85, 0.2) 75%, rgba(102, 102, 102, 0.2) 87%, rgba(119, 119, 119, 0.2) 100%);*/
    animation: animate 5s ease-in-out infinite;
    backdrop-filter: hue-rotate(12deg);
    transform-style: preserve-3d;
    transform: perspective(800px);
}

.description {
    height: 100%;
    text-align: center;
}

.description label {
    display: block;
    color: #f5f5f5;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
}

p {
    color: #fff;
    margin: 20px 10px;
}

.description p {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
}

.list {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 30px 0;
    left: 0px;
    bottom: 15px;
    position: absolute;
    padding: 15px;
    left: 0px;
    bottom: 15px;
    position: absolute;
}

i {
    color: #fff;
    font-size: 3rem;
    transition: all 0.3s ease;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.fa-twitter:hover {
    transform: translate3d(0, -10px, 20px);
    color: #00acee;
}

.fa-github:hover {
    transform: translate3d(0, -10px, 20px);
    color: gray;
}

.fa-discord:hover {
    transform: translate3d(0, -10px, 20px);
    color: #5865f2;
}

.fa-hurricane:hover {
    transform: translate3d(0, -10px, 20px);
    color: #a689fa;
}

.fa-tv:hover {
    transform: translate3d(0, -10px, 20px);
    color: #00ccff;
}

.fa-film:hover {
    transform: translate3d(0, -10px, 20px);
    color: #e5a00d;
}

.fa-cloud:hover {
    transform: translate3d(0, -10px, 20px);
    color: #0082c9;
}

.fa-square-poll-vertical:hover {
    transform: translate3d(0, -10px, 20px);
    color: #e5a00d;
}

.fa-magnifying-glass:hover {
    transform: translate3d(0, -10px, 20px);
    color: #e66001;
}

.fa-music:hover {
    transform: translate3d(0, -10px, 20px);
    color: #009252;
}

.input-box {
    background-color: rgba(255, 255, 255, 0.15);
    border: transparent;
    border-radius: 25px;
    width: 100%;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    height: 30px;
    margin-top: 35px;
    margin-bottom: 15px;
    text-align: center;
    transition-duration: 1.5s;
}

.input-box:focus {
    background-color: rgba(70, 70, 70, 0.137);
    outline: none;
    transition-duration: 0.3s;
}

.input-form {
    margin-top: 55px;
}

.input-button {
    background-color: rgba(154, 204, 207, 0.205);
    margin-top: 50px;
    width: 100%;
    border-radius: 25px;
    border: transparent;
    height: 30px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    transition-duration: 0.1s;
}
.input-button:hover {
    transform: scale(1.1);
    transition-duration: 0.1s;
}

@keyframes animate {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
